

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.title-station{
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	font-size: 22px;
	line-height: 60px;
	color: #E2E2E2;
	text-align: center;
}
